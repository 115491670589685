import { formatCurrency } from '@artemis/utils/currency-format';
import { createSelector } from 'reselect';

const getPromoCodesRoot = state => state.promoCodes;

export const getPromoCode = createSelector(
  getPromoCodesRoot,
  promoCodes => promoCodes?.data,
);

export const getPromoCodeError = createSelector(
  getPromoCodesRoot,
  promoCodes => promoCodes?.error,
);

export const getPromoCodeLoading = createSelector(
  getPromoCodesRoot,
  promoCodes => promoCodes?.isLoading,
);

export const getPromoCodeLocalizedMessage = createSelector(
  getPromoCode,
  promoCodes => promoCodes?.promo?.localizedMessage,
);

export const getPromoCodeLocalizedOffers = createSelector(
  getPromoCode,
  promoCodes => promoCodes?.promo?.localizedOffers || [],
);

export const getPromoCodeLocalizedBody = createSelector(
  getPromoCode,
  promoCodes => promoCodes?.promo?.localizedBodyText,
);

export const getPromoCodeSubjectName = createSelector(
  getPromoCode,
  promoCodes => promoCodes?.promo?.subjectName,
);

export const getPromoCodeSubjectImageUrl = createSelector(
  getPromoCode,
  promoCodes => promoCodes?.promo?.subjectImageUrl,
);

export const getPromoCodeTotalValue = createSelector(getPromoCode, promoCodes =>
  formatCurrency({
    currencyCode: promoCodes?.promo?.currencyCode,
    value: promoCodes?.promo?.totalValueMicro,
    precision: 0,
  }),
);

export const getPromoCodeTotalValueMicro = createSelector(
  getPromoCode,
  promoCodes => promoCodes?.promo?.totalValueMicro,
);

export const getPromoCodeType = createSelector(
  getPromoCode,
  promoCodes => promoCodes?.type,
);

export const getPromoCodeTeamName = createSelector(
  getPromoCode,
  promoCode => promoCode?.promo?.teamName,
);
