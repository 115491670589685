/**
 *  Note: Define events close to the feature's code instead of here when possible
 */

// Payments
export const EVENT_ACTION_ADD_CARD_CLICK =
  'R1_PAYMENTS_CHECKOUT_ADD_CARD_CLICK';
export const EVENT_ACTION_REMOVE_CARD_CLICK =
  'R1_PAYMENTS_CHECKOUT_REMOVE_CARD_CLICK';

// Promo Landing Pages (Invite, EPE, Food Fest)
export const EVENT_ACTION_PROMOTIONAL_LANDING_PAGE_IMPRESSION =
  'PROMOTIONAL_LANDING_PAGE_IMPRESSION';
export const EVENT_ACTION_PROMOTIONAL_LANDING_PAGE_MAIN_CTA_CLICK =
  'PROMOTIONAL_LANDING_PAGE_MAIN_CTA_CLICK';
