import { useMemo } from 'react';
import queryString from 'query-string';
import {
  BRANCH_ANDROID_PARAM,
  BRANCH_IOS_PARAM,
  ENTITY_ID_PARAM,
} from '@artemis/utils/query/constants';
import useRitualAnalyticsEntityId from './useRitualAnalyticsEntityId';

/**
 * Takes the Ritual invite branch.io URL and includes the promo code in the path.
 * Includes branch.io redirection query params for Android and iOS.
 * Includes the entityId from cookies, if it exists, as a query param
 * @returns {string} Branch URL
 * @example https://invite.ritual.co/WELCOME?$android_url=&$ios_url=&entity_id=123
 */
const useBranchLink = ({ promoCode }) => {
  const entityId = useRitualAnalyticsEntityId();

  const branchUrl = useMemo(
    () =>
      queryString.stringifyUrl(
        {
          url: `${process.env.RT_BRANCH_URL}/${promoCode}`,
          query: {
            [BRANCH_ANDROID_PARAM]: '',
            [BRANCH_IOS_PARAM]: '',
            ...(entityId && { [ENTITY_ID_PARAM]: entityId }),
          },
        },
        { encode: false },
      ),
    [promoCode, entityId],
  );

  return branchUrl;
};

export default useBranchLink;
