import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ResponsiveImage from '@artemis/components/ResponsiveImage';

const LogoContainer = styled.div`
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  picture {
    line-height: 0;
  }
`;

const NavLogos = ({ logos }) => (
  <LogoContainer>
    {logos.map(logoId => (
      <ResponsiveImage key={logoId} id={logoId} />
    ))}
  </LogoContainer>
);

NavLogos.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default NavLogos;
