import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
`;

const PageContainer = ({ children, ...props }) => (
  <Container {...props}>{children}</Container>
);

PageContainer.propTypes = {
  children: PropTypes.node,
};

export default PageContainer;
