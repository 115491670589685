import { useEffect, useState } from 'react';
import Cookies from '@artemis/utils/cookies';

const RA_ENTITY_ID_COOKIE = 'ra_entity_id';

const useRitualAnalyticsEntityId = () => {
  const [entityId, setEntityId] = useState(null);

  useEffect(() => {
    setEntityId(Cookies.get(RA_ENTITY_ID_COOKIE) || null);
  }, []);

  return entityId;
};

export default useRitualAnalyticsEntityId;
