import { useEffectOnce } from 'react-use';
import { getLCP, getFID, getCLS } from 'web-vitals';
import {
  EVENT_PARAM_TYPE_STRING,
  EVENT_PARAM_TYPE_INTEGER,
  EVENT_TYPE_INTERACTION,
  DOMAIN_CATEGORY_WEB_VITALS,
  PROJECT_CATEGORY_WEB_VITALS,
  FEATURE_CATEGORY_WEB_VITALS,
  getEvent,
  postEvent,
  ENTITY_TYPE_CLIENT,
} from '@artemis/utils/analytics';

export const PAGE_NAME = {
  MENU: 'MENU',
  LOCATION: 'LOCATION',
  LANDING: 'LANDING',
  CHECKOUT: 'CHECKOUT',
  STATUS: 'STATUS',
  INVITE_LANDING: 'INVITE_LANDING',
  VERIFY_TEAM: 'VERIFY_TEAM',
  EMPLOYEE_PRICING_LANDING: 'EMPLOYEE_PRICING_LANDING',
};

const EVENT_ACTION_WEB_VITALS_LCP = 'WEB_VITALS_LARGEST_CONTENTFUL_PAINT';
const EVENT_ACTION_WEB_VITALS_FID = 'WEB_VITALS_FIRST_INPUT_DELAY';
const EVENT_ACTION_WEB_VITALS_CLS = 'WEB_VITALS_CUMULATIVE_LAYOUT_SHIFT';
const WEB_VITALS_METRICS = {
  LCP: 'LARGEST_CONTENTFUL_PAINT',
  FID: 'FIRST_INPUT_DELAY',
  CLS: 'CUMULATIVE_LAYOUT_SHIFT',
};

const logWebVitals = (
  eventAction,
  eventParams,
  eventType = EVENT_TYPE_INTERACTION,
) => {
  const event = getEvent({
    eventAction,
    eventParams,
    eventType,
    entityType: ENTITY_TYPE_CLIENT,
    analyticsDomainCategory: DOMAIN_CATEGORY_WEB_VITALS,
    analyticsProjectCategory: PROJECT_CATEGORY_WEB_VITALS,
    analyticsFeatureCategory: FEATURE_CATEGORY_WEB_VITALS,
  });

  if (process.env.NODE_ENV !== 'development') postEvent(event);
};

const useTrackWebVitals = pageName => {
  const getParams = metric => [
    {
      key: 'ID',
      value: metric.id,
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'VALUE',
      value: metric.value,
      type: EVENT_PARAM_TYPE_INTEGER,
    },
    {
      key: 'NAME',
      value: WEB_VITALS_METRICS[metric.name],
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'DELTA',
      value: metric.delta,
      type: EVENT_PARAM_TYPE_INTEGER,
    },
    {
      key: 'PAGE',
      value: pageName,
      type: EVENT_PARAM_TYPE_STRING,
    },
  ];
  const logCLS = metric => {
    logWebVitals(EVENT_ACTION_WEB_VITALS_CLS, getParams(metric));
  };
  const logFID = metric => {
    logWebVitals(EVENT_ACTION_WEB_VITALS_FID, getParams(metric));
  };
  const logLCP = metric => {
    logWebVitals(EVENT_ACTION_WEB_VITALS_LCP, getParams(metric));
  };
  useEffectOnce(() => {
    getCLS(logCLS);
    getFID(logFID);
    getLCP(logLCP);
  });
};

export default useTrackWebVitals;
